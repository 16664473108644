import React, { Component } from 'react';
import { Card, Col, Row, Button, Form, CardHeader, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchAdminDashboard, fetchDashboard } from '../action';
import Cookies from 'universal-cookie';
import { fetchAllFormsList, fetchDashboardDetails } from '../../forms/action';
import { formateDate, removeMB, shortDesc, ShortenText } from '../../../lib/utils';
import DataTable from './DataTable';
import Breadcrumbs from './Breadcrumbs';
import Example from "../../charts/Chart";
import Barchart from "../../charts/Barchart";
import Pichart from "../../charts/Pichart"
import image from "../../../Images/nodata.png";
import FormIcon from "../../../Images/formicon.jpg"
import NormalForm from "../../../Images/normal_form.jpg"
import { Link } from 'react-router-dom';
const cookies = new Cookies();


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: "",
      piData: "",
      floatform: false,
      admindashboard:{}
    }
  }
  handleSearchAction() {
    this.props.fetchAllFormsList(this.props.token.access, this.state.search);
  }

  componentDidMount() {
    if (cookies.get('token') == undefined) {
      this.props.navigate('/login')
    }
    this.props.fetchDashboard(this.props.token.access);
    this.props.fetchAllFormsList(this.props.token.access);
    this.props.fetchDashboardDetails(this.props.token.access);
    this.floatControl();
    this.props.fetchadmindashboard(this.props.token.access,(responce)=>{
      this.setState({admindashboard:responce})
    })
  }

  arrangeData = (myData) => {
    let data = []
    data.push({ name: "total", value: removeMB(myData[3].total) }, { name: "uses", value: removeMB(myData[3].uses) })
    return data
  }

  floatControl = () => {
    this.setState({ floatform: true })

    setTimeout(() => {
      this.setState({ floatform: false })

    }, 10000);

  }
  render() {
   console.log(this.props.dashboardDetails?.dashboard_details?.length,'dashboard')
    return (
      <div className="main-content-container p-4 container-fluid " style={{ overflowY: "hidden" }}>

        <Breadcrumbs name={this.props} />
        <br />
        <div className="right-panel">
          {
            localStorage.getItem('key') === "admin" ?
              <>
                <div >
                  <Row>

                    <Col md="3">
                      <Card className='p-4 background_light_green'>
                        <Row>
                          <Col md="4" className='d-flex justify-content-center align-items-center shadow bg-danger rounded'>
                            <i className="fa fa-book nav_icon large text-white" aria-hidden="true"></i>
                          </Col>
                          <Col md="8" className='dashboard_card_body' >
                            <div className='text-success bold'>{this.state.admindashboard?.form_count}</div>
                            <div>Forms</div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>

                    <Col md="3">
                      <Card className='p-4 background_light_green'>
                        <Row>
                          <Col md="4" className='d-flex justify-content-center align-items-center shadow bg-warning rounded'>
                            <i className="fa fa-rocket nav_icon large text-white" aria-hidden="true"></i>
                          </Col>
                          <Col md="8" className='dashboard_card_body' >
                            <div className='text-success bold'>{this.state.admindashboard?.project_count}</div>
                            <div>Projects</div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>

                    <Col md="3">
                      <Card className='p-4 background_light_green'>
                        <Row>
                          <Col md="4" className='d-flex justify-content-center align-items-center shadow bg-primary rounded'>
                            <i className="fa fa-file-text-o nav_icon large text-white" aria-hidden="true"></i>
                          </Col>
                          <Col md="8" className='dashboard_card_body' >
                            <div className='text-success bold'>{this.state.admindashboard?.file_count}</div>
                            <div>Files Uploaded</div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>

                    <Col md="3">
                      <Card className='p-4 background_light_green'>
                        <Row>
                          <Col md="4" className='d-flex justify-content-center align-items-center shadow bg-success rounded'>
                            <i className="fa fa-sitemap nav_icon large text-white" aria-hidden="true"></i>
                          </Col>
                          <Col md="8" className='dashboard_card_body' >
                            <div className='text-success bold'>{this.state.admindashboard?.workflow_count}</div>
                            <div>Workflows</div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <hr style={{width:"100%"}}></hr>
                </div>
                <div>

                  {
                    this.props.dashboarddetails.length > 0 &&
                    <Row>
                      <Col md="4">
                        <Card>
                          <CardHeader><center style={{fontWeight:"bold"}}>Used <em>Vs</em> Subscribed</center></CardHeader>
                          <div style={{ height: "300px", width: "100%", padding: "10px" }}>
                            <Example Chartdata={this.props.dashboardDetails} dataDetails={this.props.dashboarddetails} />
                          </div>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card>
                        <CardHeader><center style={{fontWeight:"bold"}}>Last <em>7</em> Days Data Count</center></CardHeader>
                          <div>
                            <Barchart dataDetails={this.state.admindashboard?.prev_days_count} />
                          </div>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card>
                        <CardHeader><center style={{fontWeight:"bold"}}>Used  Space</center></CardHeader>
                          <div style={{ height: "300px" }}>
                            <Pichart data={this.arrangeData(this.props.dashboarddetails)} />
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  }
                </div>
                {
                  this.props.formList && this.props.formList.data && this.props.formList.data.length > 0 &&
                  <Card>
                    <div style={{ right: 0 }}>
                      <Row className='m-3 d-flex' style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <Col md='4' >
                          <Form.Control size="md" type="text" placeholder="Search Template" required value={this.state.search} onChange={(event) => { this.setState({ search: event.target.value }); }} />
                        </Col>
                        <Col md='1' className='d-flex align-items-end'>
                          <Button onClick={() => this.handleSearchAction()} variant="success" size='md' style={{ width: "120px" }}>Search</Button>
                        </Col>
                      </Row>
                    </div>
                    <div style={{ padding: "20px" }}>
                      <DataTable
                        totalItemsCount={this.props.formList.count}
                        headers={[`Title`, `DataCount`, `Last Update`, `Action`]}
                        onPageChange={(pageNumber) => this.props.fetchAllFormsList(this.props.token.access, pageNumber)}
                        body={this.props.formList.data.map((row, index) => {
                          return [
                            row.label,
                            localStorage.getItem('key') === 'admin' ? row.data_count : row.current_user_data_count,
                            formateDate(row.current_user_date_updated),
                            <Row>
                              <Col sm='12'>
                                <a style={{ textDecoration: 'none', color: 'black' }} href={`/forms/${row.id}/view_data`}>
                                  <Button onClick={() => { this.setState({ showCommentDeleteModal: true, selectedData: row }) }} variant="success" size='sm'><i className="fa fa-eye" style={{ fontSize: 20, color: "white"}} /></Button>
                                </a>
                              </Col>
                            </Row>
                          ]
                        })} />
                    </div>
                  </Card>
                }

                {this.state.floatform == true ?
                  <div className="floating_btn">
                    <Link to="/forms" style={{ textDecoration: "none" }}>
                      <div style={{ height: "50px", width: "150px", backgroundColor: "rgb(229, 95, 12", borderRadius: "30px 0px 0px 30px" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div className="contact_icon">
                            <i className="fa fa-book my-float" />
                          </div>
                          <p className='text-white' style={{ fontSize: "13px", marginTop: "6px", marginLeft: "8px" }}>Create<br />Forms</p>
                        </div>

                      </div>
                    </Link>
                  </div> : <>
                    <div className="floating_btn2">

                      <div style={{ height: "60px", width: "20px", backgroundColor: "rgb(229, 95, 12)", textAlign: "center" }} onClick={() => { this.floatControl() }}>
                        <i className="fa fa-angle-left" style={{ fontSize: 23, color: "white", marginTop: "14px" }} />
                      </div>
                    </div>

                  </>}
              </>
              :
              <>
                {this.props.formList.count >= 0 ?
                  <>
                    <div >
                    <Row>
{this.props.dashboardDetails?.dashboard_details?.map((item,index)=>{
if(item.type == 'Card'){
  // const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  const colors = ['#FF5733', '#37a596', '#095389', '#b97e13', '#FF33A2', '#33FFF5'];

      // Use the index to select a color from the array, cycling if more than 6 items
      const backgroundColor = colors[index % colors.length];
  return <>
  <Col md="3">
  <Card className='p-4 background_light_green'>
    <Row>
      <Col md="4" className='d-flex justify-content-center align-items-center shadow  rounded' style={{ backgroundColor: backgroundColor }}>
        <i className="fa fa-book nav_icon large text-white" aria-hidden="true"></i>
      </Col>
      <Col md="8" className='dashboard_card_body' >
        <div className='text-success bold'>{item.value}</div>
        <div className='text-center'>{item.name}</div>
      </Col>
    </Row>
  </Card>
</Col>
  </>
}



}
)}


</Row>
<Row>
  {this.props.dashboardDetails?.dashboard_details?.map((item, index) => {
    if (item.type === 'Table') {
      return (
        <Col md={12} className="px-4" key={index}>
          <p>{item.name}</p>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                {/* Dynamically generate column headers based on keys in the first object of the `value` array, excluding 'all_form_id' */}
                {item.value?.length > 0 &&
                  Object.keys(item.value[0])
                    .filter((key) => key !== 'all_form_id') // Exclude 'all_form_id' from column headers
                    .map((key, idx) => (
                      <th key={idx}>{key === 'max' ? 'State' : key}</th>
                    ))}
                <th></th> {/* Add an extra column for the "View" button */}
              </tr>
            </thead>

            <tbody>
              {/* Dynamically generate rows and cells for each object in the `value` array, excluding 'all_form_id' */}
              {item.value?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {/* Loop through the row and display each column's value, excluding 'all_form_id' */}
                  {Object.entries(row)
                    .filter(([key]) => key !== 'all_form_id') // Exclude 'all_form_id' from rows
                    .map(([key, val], colIndex) => (
                      <td key={colIndex}>{val !== null ? val : '-'}</td>
                    ))}
                  {/* Add the "View" button in a separate cell */}
                  <td>
                    <Link to={`/forms/${item.template}/DataDetails/${row.all_form_id}`}>
                      <Button title='View' variant="success" size='sm'>
                        <i className="fa fa-eye" style={{ fontSize: "15px", color: "white" }} />
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      );
    }
    return null; // Skip non-'Table' items
  })}
</Row>

{this.props.dashboardDetails?.dashboard_details?.length == 0  && 
                      <Row>

                        <Col md="3">
                          <Card className='p-4 background_light_green'>
                            <Row>
                              <Col md="4" className='d-flex justify-content-center align-items-center shadow bg-danger rounded'>
                                <i className="fa fa-book nav_icon large text-white" aria-hidden="true"></i>
                              </Col>
                              <Col md="8" className='dashboard_card_body' >
                                <div className='text-success bold'>{this.props.dashboardDetails.form_count}</div>
                                <div>Forms</div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>

                        <Col md="3">
                          <Card className='p-4 background_light_green'>
                            <Row>
                              <Col md="4" className='d-flex justify-content-center align-items-center shadow bg-warning rounded'>
                                <i className="fa fa-rocket nav_icon large text-white" aria-hidden="true"></i>
                              </Col>
                              <Col md="8" className='dashboard_card_body' >
                                <div className='text-success bold'>{this.props.dashboardDetails.project_count}</div>
                                <div>Projects</div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>

                        <Col md="3">
                          <Card className='p-4 background_light_green'>
                            <Row>
                              <Col md="4" className='d-flex justify-content-center align-items-center shadow bg-primary rounded'>
                                <i className="fa fa-file-text-o nav_icon large text-white" aria-hidden="true"></i>
                              </Col>
                              <Col md="8" className='dashboard_card_body' >
                                <div className='text-success bold'>{this.props.dashboardDetails.file_count}</div>
                                <div>Files Uploaded</div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>

                        <Col md="3">
                          <Card className='p-4 background_light_green'>
                            <Row>
                              <Col md="4" className='d-flex justify-content-center align-items-center shadow bg-success rounded'>
                                <i className="fa fa-sitemap nav_icon large text-white" aria-hidden="true"></i>
                              </Col>
                              <Col md="8" className='dashboard_card_body' >
                                <div className='text-success bold'>{this.props.dashboardDetails.workflow_count}</div>
                                <div>Workflows</div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
  }
                      <hr style={{width:"100%"}}></hr>
                    </div>
                    {/* <Card>
                  <div style={{ right: 0 }}>
                    <Row className='m-3 d-flex' style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                      <Col md='4' >
                        <Form.Control size="md" type="text" placeholder="Search Template" required value={this.state.search} onChange={(event) => { this.setState({ search: event.target.value }); }} />
                      </Col>
                      <Col md='1' className='d-flex align-items-end'>
                        <Button onClick={() => this.handleSearchAction()} variant="success" size='md' style={{ width: "120px" }}>Search</Button>
                      </Col>
                    </Row>
                  </div>
                  {this.props.formList && this.props.formList.data && this.props.formList.data.length > 0 &&
                    <div style={{ padding: "20px" }}>
                      <DataTable
                        totalItemsCount={this.props.formList.count}
                        headers={[`Title`, `DataCount`, `Last Update`, `Action`]}
                        onPageChange={(pageNumber) => this.props.fetchAllFormsList(this.props.token.access, pageNumber)}
                        body={this.props.formList.data.map((row, index) => {
                          return [
                            row.label,
                            localStorage.getItem('key') === 'admin' ? row.data_count : row.current_user_data_count,
                            formateDate(row.current_user_date_updated),
                            <Row>
                              <Col sm='12'>
                                <a style={{ textDecoration: 'none', color: 'black' }} href={`/forms/${row.id}/view_data`}>
                                  <Button onClick={() => { this.setState({ showCommentDeleteModal: true, selectedData: row }) }} variant="success" size='sm' className="m-1" style={{ width: "100px" }}>View</Button>
                                </a>
                              </Col>
                            </Row>
                          ]
                        })} />
                    </div>


                  }
                </Card>  */}
                    <div className='container-fluid row'>

                      {/* {this.props.formList && this.props.formList.data.map((item, index) => {
                        return (
                          <div className='col-sm-2'>
                            <Link to={`/forms/${item.id}/view_data`} style={{ textDecoration: "none", }}>
                              <Card className='p-2 pt-4 background_light_green' style={{ height: "150px", border: "none", backgroundColor: "#63afab" }}>
                                <center><div>
                                  <img src={item.type == 'C' ? FormIcon : NormalForm} style={{ height: "30%", width: "30%", marginBottom: 10 }} alt='img'></img>
                                  <p style={{ fontSize: "13px", color: "white" }}>{ShortenText(item.label)}</p>
                                  <p style={{ fontSize: "13px", marginTop: "-19px", color: "white" }}>DataCount: <span style={{ color: "yellow", fontSize: "15px", fontWeight: "400" }}>{item.data_count}</span></p>
                                </div>
                                </center>
                              </Card>
                            </Link>
                          </div>
                        )

                      })} */}



                    </div>
                  </> :
                  <div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={image} style={{ width: "25%", height: "25%", paddingTop: "16%" }} alt='img'></img>
                      <br />

                    </div>
                    <div><center><p style={{ fontSize: "12px", marginLeft: "10px", color: "darkorange" }}>Opps ! You Don't Have Any Forms Yet</p></center>  </div>
                  </div>}
              </>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.usersReducer.token,
    dashboardDetails: state.commonReducer.dashboardDetails,
    formList: state.formsReducer.allFormList,
    dashboarddetails: state.formsReducer.dashboarddetails,
    billinguser: state.billingReducer.billinguser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDashboard: (token) => dispatch(fetchDashboard(token)),
    fetchadmindashboard:(token,fun)=>dispatch(fetchAdminDashboard(token,fun)),
    fetchAllFormsList: (accessToken, searchQuery, pageNumber) => dispatch(fetchAllFormsList(accessToken, searchQuery, pageNumber)),
    fetchDashboardDetails: (accessToken) => dispatch(fetchDashboardDetails(accessToken))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
