import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import FieldHandler from './fields/FieldHandler';
import { arrangeFormData, formateDate, getFieldLabelArray, getFieldValue, dynamicFormDataChangeHandler, countdown, sortPositionWise, validateRequireds, validateForm, getDataFromList } from '../../../lib/utils';
import { fetchPublicCustomSharedForm, fetchPublicSharedForm, fetchPublicSharedFormData, savePublicSharedFormData } from '../action';
import { redirect } from '../../../lib/utils';
import Cookies from 'universal-cookie';
import Toaster from '../../common/components/Toaster';
import CustomFieldHandler from '../../customforms/customfields/FieldHandler';
import { validator } from '../../../lib/Validation';
import { setNotification } from '../../common/action';
// import "../../../../src/styles.css"

const cookies = new Cookies();


class SharedPublicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data_obj: {},
      timing: "",
      timerOff: false,
      cardList: [],
      currentCardNo: null,
      currentCardDetails: {},
      data: [],
      formDetails: {},
      validationResponse: false
    }
  }
  handleDropdownChange = (res) => {
    // Update the state with the new alldropdown value
    this.setState({ alldropdown: res });
};
  validateData_obj = () => {
    var data = { ...this.state.data_obj }
    var myList = Object.keys(data).length > 0 && Object.keys(data).map((item, index) => {
      return { 'value': data[item], 'field': parseInt(item) }
    })
    return myList
  }


  setDataToState = (data) => {
    this.state.data_obj[data.field] = data.value
    this.setState({ data_obj: this.state.data_obj })
  }


  componentDidMount() {
    this.props.getPublicSharedForm(this.props.params.token);

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sharedFormFields.results.length !== prevProps.sharedFormFields.results.length) {
      if (this.props.sharedFormFields.results[0] && this.props.sharedFormFields.results[0]['template']) {
        if (this.props.sharedFormFields.results[0]['template'].type == "C") {
          this.props.fetchPublicCustomSharedForm(this.props.params.form_id, this.props.params.token, (custom_form) => {
            this.setState({ formDetails: JSON.parse(custom_form.data[0].custom_form_data), cardList: (JSON.parse(custom_form.data[0].custom_form_data).cards && JSON.parse(custom_form.data[0].custom_form_data).cards.length > 0) ? sortPositionWise(JSON.parse(custom_form.data[0].custom_form_data).cards) : [] })
          })
        }
        var time = this.props.sharedFormFields.results[0]['template']['timmer']
        const intervalId = setInterval(() => {
          if (time > 0) {
            time--;
            const minutes = Math.floor(time / 60);
            const seconds = time % 60;
            this.setState({ timing: `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` });
          } else {
            if (this.props.sharedFormFields.results[0]['template']['timmer'] !== 0) {
              clearInterval(intervalId);
              this.setState({ timerOff: true })
              if (time == 0) {
                this.handleSubmitAction()
              }
            }
          }
        }, 1000);
      }

    }
    // if (prevState.timerOff !== this.state.timerOff) {

    //   if()
    //   this.handleSubmitAction()
    // }
    const persistedState = cookies.get('token')
    if (prevProps.sharedFormFields !== this.props.sharedFormFields) {
      if (this.props.sharedFormFields?.results[0][`scope`] === `VIEW`) {
        if (this.props.sharedFormFields?.results[0][`permission`] === `ANYONE_WITH_LINK`) {
          this.props.getPublicSharedFormData(this.props.params.token);
        }
        else {
          if (persistedState == undefined) {
            redirect(`/login/${this.props.sharedFormFields.results[0]['token']}`);
          }
        }
      }
    }
  }

  handleDataChange(fieldId, value) {
    this.setState({ data: dynamicFormDataChangeHandler(this.state.data, fieldId, value) })
  }

  handleSubmitAction = () => {
    // e.preventDefault()
    let requestBody = {
      "data": this.props.sharedFormFields.results[0]['template'].type == 'C' ? this.validateData_obj() ? this.validateData_obj() : [] : this.state.data
    }
    this.props.storeData(this.props.params.token, requestBody, this.props.navigate);
  }


  onSubmitForm = () => {
    var myList = this.validateData_obj()
    if (myList) {
      var requiredData = validateRequireds(this.props.sharedFormFields.results[0]['template_fields'], myList)
      if (requiredData) {
        alert(`${requiredData} field is required.`)
      }
      else {
        if (validateForm(this.props.sharedFormFields.results[0]['template_fields'], myList)) {
          let requestBody = {
            "data": this.props.sharedFormFields.results[0]['template'].type == 'C' ? this.validateData_obj() ? this.validateData_obj() : [] : this.state.data
          }
          if (this.state.validationResponse === false) {
            this.props.storeData(this.props.params.token, requestBody, this.props.navigate);
          }
          else {
            alert('Duplicate data not allowed')
          }
        }
        else {
          this.props.sharedFormFields.results[0]['template_fields'].length > 0 && this.props.sharedFormFields.results[0]['template_fields'].map((item) => {
            alert(`${item.label.toUpperCase()} Field : ${this.validateForm(item.validation, getDataFromList(myList, item.id))}`)
          })
        }
      }
    }
    else {
      alert("Please Fill The Input Fields")
    }
  }


  validateForm(validationObj, value) {
    if (validationObj === null) {
      return
    } else {
      if (!validator(validationObj, value) && value.length > 0) {
        return validationObj.error_message;
      }
    }
    return
  }

  render() {
    console.log(this.props.sharedFormFields, 'sharedFormFields');
    return (
      <div className="ctrlqFormContentWrapper">
        {this.props.sharedFormFields && this.props.sharedFormFields.results && this.props.sharedFormFields.results.length > 0 ?
          (this.props.sharedFormFields.results[0]['template'].type == 'C' && this.props.sharedFormFields.results[0]['template'].custom_template_type == 'C') ? <div style={{ backgroundImage: `url(${this.props.sharedFormFields.results[0]['template']['image_header']})`, position: "fixed", height: window.innerHeight, width: window.innerWidth, backgroundSize: "cover" }}></div> :
            <div className="ctrlqHeaderMast" style={{ backgroundImage: `url(${this.props.sharedFormFields.results[0]['template']['image_header']})` }}></div> :
          <div className="ctrlqHeaderMast"></div>
        }
        {this.props.sharedFormFields.results.length > 0 &&
          <div className="ctrlqCenteredContent">
            {(this.props.sharedFormFields.results[0]['template'].type == 'C' && this.props.sharedFormFields.results[0]['template'].custom_template_type == 'C') ?
              <div style={{ width: "100%" }} >
                <center>
                  <div style={{ width: "65%" }}>
                    {this.state.currentCardNo == null &&
                      <Card className='current-card' style={{ backgroundColor: "#fff", marginTop: "5%", borderRadius: 10, marginBottom: "3%" }}>
                        <div style={{ backgroundImage: `url(${this.state.formDetails.image_header})`, ...styles.form_details }} title='Form Details'>
                          <h4 style={{ marginInline: "5%", textAlign: "center", fontWeight: "bolder" }}>
                            {this.state.formDetails.label && this.state.formDetails.label.toUpperCase()}
                          </h4>
                          <p style={{ textAlign: "center" }}>{this.state.formDetails.description && this.state.formDetails.description}</p>
                          <p style={{ textAlign: "center" }}><b>{this.state.cardList.length}</b>  cards</p>
                        </div>
                        <div style={styles.next_btn}>
                          <div onClick={() => {
                            this.setState({ currentCardNo: 0, currentCardDetails: this.state.cardList[0] })
                          }} className='card-button' style={{ textAlign: "enter" }}> NEXT <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i></div>
                        </div>
                      </Card>
                    }

                    {
                      (Object.keys(this.state.currentCardDetails).length > 0 && this.state.currentCardNo !== null) &&
                      <Card
                        className='current-card' style={{ backgroundColor: "#fff", borderRadius: 10, marginBottom: "3%", marginTop: "5%" }}>

                        <div style={{ backgroundImage: `url(${this.state.formDetails.image_header})`, ...styles.card_details }} title='Card Details'>
                          <div style={{ width: "85%" }}>
                            <h5 style={{ marginInline: "5%", textAlign: "center" }}>{this.state.currentCardDetails.card_name && this.state.currentCardDetails.card_name.toUpperCase()}</h5>
                            <p style={{ textAlign: "center" }}>{this.state.currentCardDetails.card_desc && this.state.currentCardDetails.card_desc}</p>
                          </div>
                        </div>
                        <Row style={{ display: "flex", paddingBlock: 30 }} className='custom-form'>
                          {
                            this.props.sharedFormFields.results[0]['template_fields'].length > 0 && this.props.sharedFormFields.results[0]['template_fields'].map((item, index) => {
                              if (item.type !== 'OCR_VIEW' && item.card_no == this.state.currentCardDetails.id) {
                                return (
                                  <Col md={item.style == '' ? 12 : item.style} style={{ display: "flex", justifyContent: "center", marginBottom: 25 }}
                                    key={index}>
                                    <CustomFieldHandler
                                    alldropdown={(res) => this.handleDropdownChange(res)}
                                    finalDropdownData={this.state.alldropdown}
                                      data={item}
                                      type={item.type}
                                      editable={false}
                                      placeholder={''}
                                      Sharetoken={this.props.params.token}
                                      value={getFieldValue(item, this.validateData_obj() ?? [], false)}
                                      onChange={(data) => {
                                        this.setDataToState(data);
                                      }}
                                      onBlur={(data) => { }}
                                    />
                                  </Col>
                                )
                              }
                            })
                          }
                        </Row>
                        {
                          this.state.cardList.length - 1 == this.state.currentCardNo ?
                            <div style={styles.previous_btn}>
                              <div className='card-button' onClick={() => {
                                if (this.state.currentCardNo == 0) { this.setState({ currentCardNo: null }) }
                                else {
                                  this.setState({ currentCardDetails: this.state.cardList[this.state.currentCardNo - 1], currentCardNo: this.state.currentCardNo - 1, })
                                }
                              }}><i className="fa fa-long-arrow-left" aria-hidden="true" style={{ marginRight: 7, fontSize: 17 }}></i> PREVIOUS</div>
                              <div style={{ width: 0.1, backgroundColor: "#fff" }}></div>
                              <div className='card-button' style={{ textAlign: "end" }}
                                onClick={() => { this.onSubmitForm() }}> SUBMIT <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i>
                              </div>
                            </div>
                            :
                            <div style={styles.previous_btn}>
                              <div className='card-button' onClick={() => {
                                if (this.state.currentCardNo == 0) { this.setState({ currentCardNo: null }) }
                                else {
                                  this.setState({ currentCardDetails: this.state.cardList[this.state.currentCardNo - 1], currentCardNo: this.state.currentCardNo - 1, })
                                }
                              }}><i className="fa fa-long-arrow-left" aria-hidden="true" style={{ marginRight: 7, fontSize: 17 }}></i> PREVIOUS</div>
                              <div style={{ width: 0.1, backgroundColor: "#fff" }}></div>
                              <div className='card-button' style={{ textAlign: "end" }}
                                onClick={() => {
                                  this.setState({ currentCardDetails: this.state.cardList[this.state.currentCardNo + 1], currentCardNo: this.state.currentCardNo + 1, })
                                }}> NEXT <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i>
                              </div>
                            </div>
                        }

                      </Card>
                    }
                  </div>
                </center>
              </div>
              :
              <div className="ctrlqFormCard">
                <div className="ctrlqAccent"></div>
                <div className="ctrlqFormContent">



                  <div className="row">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      {this.props.sharedFormFields.results.length > 0 &&
                        <>
                          <div className="input-field col s12" style={{ paddingInlineStart: this.props.sharedFormFields.results[0]['template'].type == 'C' ? "5%" : 0 }}>
                            <h4>{this.props.sharedFormFields.results[0]['template']['label']}</h4>
                            <p>{this.props.sharedFormFields.results[0]['template']['description']}</p>
                          </div>
                          {(this.props.sharedFormFields.results[0]['template']['timmer'] !== null && this.props.sharedFormFields.results[0]['template']['timmer'] !== 0) && <div><p style={{ fontSize: "20px" }}><i class="fa fa-clock-o" aria-hidden="true" style={{ color: "red" }}></i> {this.state.timing}</p></div>}

                        </>
                      }

                    </div>
                  </div>
                  <hr />

                  <div className="main-content-container container-fluid pt-3 pb-3" >

                    <Row className='d-flex justify-content-center'>

                      <Col md='12'>
                        {this.props.sharedFormFields.results[0][`scope`] === `ADD` ?
                          <div >
                            {this.props.sharedFormFields.results[0]['template'].type == 'C' ?
                              <Row style={{ display: "flex", }} className='custom-form'>
                                {this.props.sharedFormFields.results[0]['template_fields'].map((item, index) => {
                                  return (
                                    <Col md={item.style == '' ? 12 : item.style} style={{ display: "flex", justifyContent: "center", marginBottom: 25 }}
                                      key={index}>
                                      <CustomFieldHandler
                                      alldropdown={(res) => this.handleDropdownChange(res)}
                                      finalDropdownData={this.state.alldropdown}
                                        data={item}
                                        type={item.type}
                                        Sharetoken={this.props.params.token}
                                        editable={false}
                                        placeholder={''}
                                        value={getFieldValue(item, this.validateData_obj() ? this.validateData_obj() : [], false)}
                                        onChange={(data) => {
                                          var new_obj = { ...this.state.data_obj }
                                          new_obj[data.field] = data.value
                                          this.setState({ data_obj: new_obj })
                                        }}
                                        onBlur={(data) => { }} />
                                    </Col>
                                  )

                                })}

                                <Row className='d-flex justify-content-center'>
                                  <Col md={2}>
                                    <Button style={{ width: "100%" }} onClick={(e) => { this.handleSubmitAction(e) }} variant="outline-success" className='mt-4'>SUBMIT</Button>
                                  </Col>
                                </Row>
                              </Row>
                              :
                              <div>
                                <Row>
                                  {this.props.sharedFormFields.results[0]['template_fields'].map((field, index) => {
                                    return <FieldHandler
                                      fieldData={field}
                                      index={index}
                                      Sharetoken={this.props.params.token}
                                      value={getFieldValue(field, this.state.data)}
                                      handleDataChange={(field_id, value) => {
                                        this.handleDataChange(field_id, value);
                                      }}
                                      onBlur={() => { }} />

                                  })}
                                </Row>

                                <Row>
                                  <Col md={6}>
                                    <Button onClick={(e) => { this.handleSubmitAction(e) }} variant="outline-success" className='mt-4'>SUBMIT</Button>
                                  </Col>
                                </Row>
                              </div>
                            }
                          </div> :
                          <Card style={{ overflowY: "scroll", padding: "1%" }}>
                            <DataTable
                              totalItemsCount={0}
                              headers={getFieldLabelArray(this.props.sharedFormFields.results[0]['template_fields'])}
                              onPageChange={(pageNumber) => { }}
                              body={
                                this.props.sharedFormData.results.map((row, index) => {
                                  let data = arrangeFormData(this.props.sharedFormFields.results[0]['template_fields'], row.data, this.props.params.token);
                                  data.push(formateDate(row.date_updated))
                                  data.push(row.user === null ? 'Anonymous' : row.user.name)
                                  data.push(
                                    <button type="button" className="btn btn-outline-success">Download</button>
                                  )
                                  return data;
                                })
                              } />
                          </Card>
                        }
                      </Col>
                    </Row>
                  </div>


                </div>
              </div>
            }
          </div>
        }
      </div>

    );
  }
}


const styles = {
  form_details: { paddingTop: "3%", paddingBottom: "2%", height: "100%", width: "100%", backgroundSize: "cover", fontFamily: "serif" },
  next_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "center", padding: 10, cursor: "pointer" },
  card_details: { paddingTop: "3%", height: "100%", width: "100%", backgroundSize: "cover", fontFamily: "serif", borderBottomStyle: "dashed", borderBottomWidth: 1, display: "flex", justifyContent: "space-evenly" },
  card_btn: { position: "relative", float: "right", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" },
  submit_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "center", padding: 10, paddingInline: 20, cursor: "pointer" },
  previous_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "space-between", padding: 10, paddingInline: 20, cursor: "pointer" },
  drag_field: { display: "flex", alignItems: "center", justifyContent: "center", borderStyle: "dashed", borderRadius: 15, marginInline: "5%", paddingBlock: 30 }
}

const mapStateToProps = state => {
  return {
    sharedFormFields: state.formsReducer.sharedFormFields,
    sharedFormData: state.formsReducer.sharedFormData,
    token: state.usersReducer.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPublicSharedForm: (token) => dispatch(fetchPublicSharedForm(token)),
    getPublicSharedFormData: (token, pageNumber) => dispatch(fetchPublicSharedFormData(token, pageNumber)),
    storeData: (token, requestBody, navigate) => dispatch(savePublicSharedFormData(token, requestBody, navigate)),
    fetchPublicCustomSharedForm: (form_id, token, returnedData) => dispatch(fetchPublicCustomSharedForm(form_id, token, returnedData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedPublicForm);
