import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { REQUEST_METHOD } from '../../../lib/constants';
import { fetchApi } from '../../../lib/utils';
import { setNotification, updateLoader } from '../../common/action';
import { fetchFields, fetchMultiSearchData } from '../../forms/action';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {}, // Stores options for each dropdown by field ID
            updatedOptions: {} // Stores updated options from localStorage
        };
    }

    componentDidMount() {
        this.fetchOptions();
        this.loadLocalStorageData();
    }

    componentDidUpdate(prevProps, prevState) {
        // Only update localStorage if the options state has changed
        if (prevState.options !== this.state.options) {
            this.updateLocalStorage(this.state.options);
        }
    }

    // A method to update localStorage with the latest options
    updateLocalStorage = (newOptions) => {
        const localStorageKey = 'localstate';

        // Retrieve existing options from localStorage (if any).
        const existingOptions = JSON.parse(localStorage.getItem(localStorageKey)) || {};

        // Iterate over the new options and update the existing options in localStorage if the fieldId exists
        Object.keys(newOptions).forEach(fieldId => {
            // Directly update the fieldId key with the new options
            existingOptions[fieldId] = newOptions[fieldId];
        });

        // Update localStorage with the updated options
        localStorage.setItem(localStorageKey, JSON.stringify(existingOptions));

        // Ensure the state is updated immediately with the latest data from localStorage
        this.setState({ updatedOptions: existingOptions });
    };

    loadLocalStorageData = () => {
        const localStorageKey = 'localstate';
        const localData = JSON.parse(localStorage.getItem(localStorageKey)) || {};

        // Update state with localStorage data if available
        this.setState({ updatedOptions: localData });
    };

    fetchOptions = () => {
        const { fieldData, ShareToken, token, setLoader } = this.props;
        const fieldId = fieldData.id;

        // Fetching options based on master_data_code
        if (fieldData.master_data_code !== 'string') {
            setLoader(true);
            const url = token
                ? `/forms/template/${fieldData.master_data_code}/data/?all=true`
                : `/forms/template/${fieldData.master_data_code}/${ShareToken}/data/?all=true`;

            fetchApi(
                url,
                token?.access,
                REQUEST_METHOD.GET,
                (successResponse) => {
                    const data = successResponse.data || [];
                    const options = data.flatMap(row =>
                        row.data.map(item => item.value) // Extracting `value` property
                    );

                    // Set options for this specific field ID and update localStorage immediately
                    this.setState(
                        prevState => {
                            const newOptions = { ...prevState.options, [fieldId]: options };
                            this.updateLocalStorage(newOptions); // Update localStorage after setting state
                            return { options: newOptions };
                        },
                        () => {
                            setLoader(false);
                        }
                    );
                },
                () => setLoader(false)
            );
        } else if (fieldData.values) {
            setLoader(true);
            const options = fieldData.values.includes(',')
                ? fieldData.values.split(',').map(item => item.trim())
                : [fieldData.values];

            // Update state and localStorage immediately
            this.setState(
                prevState => {
                    const newOptions = { ...prevState.options, [fieldId]: options };
                    this.updateLocalStorage(newOptions); // Update localStorage after setting state
                    return { options: newOptions };
                },
                () => setLoader(false)
            );
        }
    };

    parseOptions = (data) => {
        if (Array.isArray(data) && typeof data[0] === 'string') {
            return data;
        }
        return data.flatMap(item =>
            item.data.map(subItem => subItem.value)
        );
    };

    handleChange = (event) => {
        const selectedValue = event.target.value;
        const selectedIndex = event.target.selectedIndex - 1;
        const { fieldData, onChange } = this.props;
    
        // Clear the selection if the placeholder is selected
        if (selectedIndex < 0) {
            onChange('');
            return;
        }
    
        // Call the parent onChange handler
        onChange(selectedValue);
    
        const eventArray = fieldData?.event || [];
        eventArray.forEach(({ for_field, datas }) => {
            console.log(for_field, 'for_field', datas, 'datas');
    
            // Check if there is exactly one data item, and its type is 'dropdown'
            if (datas.length === 1 && datas[0].type === 'dropdown') {
                const { datasource_template, filter, response } = datas[0];
                const forFieldOptions = `'${filter}:${selectedValue}'`;
                console.log(forFieldOptions, 'forFieldOptions');
    
                // Fetch data based on the filtered dropdown type
                this.props.fetchMultiSearchData(
                    this.props.token?.access,
                    datasource_template,
                    forFieldOptions,
                    response,
                    (apiResponse) => {
                        const responseData = apiResponse.data || [];
                        const parsedOptions = this.parseOptions(responseData);
    
                        // Update state with the fetched options and immediately update localStorage
                        this.setState(
                            (prevState) => {
                                const newOptions = {
                                    ...prevState.options,
                                    [for_field]: parsedOptions,
                                };
                                this.updateLocalStorage(newOptions); // Update localStorage after setting state
                                return { options: newOptions };
                            },
                            () => {
                                // Notify the parent component with updated options
                                this.props.optionData(this.state.updatedOptions);
                            }
                        );
                    }
                );
            } 
            else {
                if(localStorage.getItem('datedropdown')){
                    const queryParts = datas.map(({ type, filter, id }) => {
                        if (type === 'dropdown') {
                            return `${filter}:${selectedValue}`;
                        } else if (type === 'date') {
                            let date=localStorage.getItem('datedropdown');
                            let parseddate=JSON.parse(date);
                            
                            return `${filter}:${parseddate?.value}`;
                        }
                        return null; // Skip unsupported types
                    }).filter(Boolean); // Remove null values
        
                    const combinedQuery = `'${queryParts.join(',')}'`;
                    console.log(combinedQuery, 'combinedQuery');
        
                    // Use the combined query to fetch data
                    const datasource_template = datas[0]?.datasource_template; // Assuming all share the same template
                    const response = datas[0]?.response;
        
                    this.props.fetchMultiSearchData(
                        this.props.token?.access,
                        datasource_template,
                        combinedQuery,
                        response,
                        (apiResponse) => {
                            const responseData = apiResponse.data || [];
                            const parsedOptions = this.parseOptions(responseData);
        
                            this.setState(
                                (prevState) => {
                                    const newOptions = {
                                        ...prevState.options,
                                        [for_field]: parsedOptions,
                                    };
                                    this.updateLocalStorage(newOptions);
                                    return { options: newOptions };
                                },
                                () => {
                                    this.props.optionData(this.state.updatedOptions);
                                }
                            );
                        }
                    );  
                }
                else{
                    this.props.setNotification(true, 'Please Select Date First', "danger");
                }
                // Handle multiple types in a single query
                
            }
        });
    };
    

    render() {
        const { fieldData, selected, required, editable } = this.props;
        const currentOptions = this.props.finaldropdownData == undefined
            ? this.state.options[fieldData.id]
            : this.props.finaldropdownData[fieldData.id];

        return (
            <Form.Select
                value={selected} // Bind the selected value
                required={required}
                disabled={editable}
                title={fieldData.tooltip}
                aria-label="Default select example"
                onChange={this.handleChange}
            >
                <option value="">-{fieldData.placeholder}-</option>
                {currentOptions?.map((option, index) => (
                    <option value={option} key={index}>{option}</option>
                ))}
            </Form.Select>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (status) => dispatch(updateLoader(status)),
    fetchMultiSearchData: (accessToken, template_id, query, response_id, callback) =>
        dispatch(fetchMultiSearchData(accessToken, template_id, query, response_id, callback)),
    setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
