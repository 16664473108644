import { fetchApi, redirect } from "../../lib/utils";
import { REQUEST_METHOD, RESPONSE_TYPE } from "../../lib/constants";
import { LOADING_START, LOADING_END } from "../common/actionTypes";
import Cookies from 'universal-cookie';
import { BASE_URL } from "../../lib/constants";
import { USER_PROFILE, FETCH_TOKEN, USER_LOCATION } from "./actionTypes";
import { fetcInternal, setNotification } from "../common/action";
import { fetchUserList } from "../common/action";
import { fetchBillingUser } from "../Billing/action";

const cookies = new Cookies();

export function fetchAccessToken(requestBody, navigate) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/token/`,
            null,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                cookies.set('token', successResponse, { path: '/' });
                localStorage.setItem('token', successResponse.access)
                dispatch({ type: FETCH_TOKEN, payload: successResponse });
                dispatch(fetchBillingUser(successResponse.access, navigate, true));
                if (window.location.pathname == '/login' || window.location.pathname == '/') {
                    localStorage.setItem('key', 'user', { path: '/' });
                    navigate('/dashboard')
                }
                else {
                    navigate(window.location.pathname.replace('/login', '/forms/public'))
                }
            },
            (failureResponse) => {
                console.log(failureResponse)
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function createUser(requestBody, navigate) {
    var Url = `${window.location.origin}${"/VerifyUser"}`
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/register/?redirect_url=${Url}`,
            null, REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, 'Congratulations! Your account has been created successfully. Please verify your email by clicking on the link sent to the email address you provided. ', 'success'))
                dispatch({ type: LOADING_END });
                redirect('/registers')

            },
            (failureResponse) => {
                console.log("failureResponse", failureResponse)
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function updatePassword(accesstoekn, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/change_password/`,
            accesstoekn,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Password Changed Successfully', 'success'))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function getUserProfile(accesstoken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/profile/`,
            accesstoken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: USER_PROFILE, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function updateUserProfile(accesstoekn, requestBody, userid) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/update/${userid}`,
            accesstoekn,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'User Updated Successfully', 'success'))
                dispatch(fetcInternal(accesstoekn))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.msg, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody
        )
    }
}


export function updateProfile(accesstoken, requestBody) {

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accesstoken}`);
    let formdata = new FormData();
    formdata.append("profile", requestBody.profile);
    formdata.append("name", requestBody.name);
    formdata.append("phone", requestBody.phone);
    formdata.append("password", requestBody.password);
    formdata.append("email", requestBody.email);
    formdata.append("is_superuser", requestBody.is_superuser);
    let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetch(`${BASE_URL}/user/profile/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch(getUserProfile(accesstoken))
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Profile updated Successfully', 'success'))
            }
            )
            .catch(error => {
                console.log('error', error);
                dispatch({ type: LOADING_END });
            });
    }
}

export function removeAccesstoken(accessToken, requestBody, navigate) {
    cookies.remove("token");
    localStorage.removeItem('key');
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/logout/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                redirect('/login');
            },
            async (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                cookies.remove("token");
                await redirect('/login')
                dispatch({ type: LOADING_END })
            },
            requestBody, RESPONSE_TYPE.NULL)
    }
}

export function removeUser(accessToken, userId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START });
        fetchApi(`/user/delete/${userId}`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(setNotification(true, 'User Removed Successfully', 'success'));
                dispatch(fetchUserList(accessToken));
                dispatch({ type: LOADING_END })
            },
            (failureResponse) => {
                
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            }, null, RESPONSE_TYPE.NULL
        )
    }
}

export function getResetPassword(requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/request-reset-email/`,
            null, REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setNotification(true, 'Email sent Successfully', 'success'))
                dispatch({ type: LOADING_END })
                redirect('/login')

            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}

export function fetchLocation(id, startDate, endDate) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/location/user/${id}/${startDate}/${endDate}`,
            null,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: USER_LOCATION, payload: successResponse })
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}
export function fetchconfurigation(getData) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/common/confurigations/`,
            null,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                localStorage.setItem('confurigation', JSON.stringify(successResponse))
                getData(successResponse)
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}
export function updateSharedData(requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/password-reset-complete`,
            null,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Password Update Sucessfully', 'success'))
                //   dispatch(fetchSharedData(accessToken, templateId))
                redirect('/login')
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}
export function verifyEmail(token) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/user/email-verify/?token=${token}`,
            null,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'Email Varify Sucessfully', 'success'))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.statusText, 'danger'))
                dispatch({ type: LOADING_END })
            },
        )
    }
}


export function createInternalUser(accesstoekn, requestBody) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        var Url = `${window.location.origin}${"/VerifyUser"}`
        fetchApi(`/user/internal/register/?redirect_url=${Url}`,
            accesstoekn,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setNotification(true, 'USer Created Successfully', 'success'))
                dispatch(fetcInternal(accesstoekn,1,''))
            },
            (failureResponse) => {
                dispatch(setNotification(true, failureResponse.msg, 'danger'))
                dispatch({ type: LOADING_END })
            },
            requestBody)
    }
}


export function getCustomDashboard(accesstoken,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/custom_dashboard/`,
            accesstoken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                fun(successResponse)
            },
            (failureResponse) => {
                
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}

export function getValueSelectGroup(accesstoken,tempid,fun) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(`/forms/template/${tempid}/alldata/`,
            accesstoken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                fun(successResponse)
            },
            (failureResponse) => {
                
                dispatch(setNotification(true, failureResponse, 'danger'))
                dispatch({ type: LOADING_END })
            }
        )
    }
}