import React, { Component } from "react";
import "../../../layouts/StyleSheets/Navbar.css";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import GIGA from "../../../Images/final.png"
import ProfileImageDropdown from "../../../layouts/ProfileImageDropdown";
import Toaster from "../../common/components/Toaster";
import { redirect } from "../../../lib/utils";
import { getUserProfile } from "../../users/action";


class CustomNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showToggleBottom: false,
            showProfile: false,
        };
    }

    componentDidMount() {
        this.props.getUserProfile(this.props.token?.access);
    }

    render() {
        return (
            <div className="header-section" id="sticky">
                <nav className="navbar navbar-expand-lg navbar-light primary">
                    <div className="container-fluid ">
                        <div className="d-inline-flex align-items-center">
                            <a href="/dashboard"><img src={GIGA} style={{ width: "100px", marginLeft: "20px" }} alt="img"/></a>
                        </div>
                        <div className="d-inline-flex">

                            <div className="d-flex align-items-center">
                                {
                                    this.props.shareFormUrl ?
                                        <div className="d-inline-flex">
                                            {/* <Button className="me-3" variant="light" onClick={() => this.props.getEmbededCode()}>Get Embeded Code</Button> */}
                                            <Link to={this.props.shareFormUrl}>
                                                <Button className="me-3" variant="light">SHARE</Button>
                                            </Link>
                                        </div>
                                        :
                                        <Button className="me-3" onClick={() => {
                                            localStorage.getItem("key") === 'user' ? localStorage.setItem('key', 'admin') : localStorage.setItem('key', 'user');
                                            window.location.reload();
                                            redirect('/dashboard')
                                        }} variant="light">{localStorage.getItem('key') === 'user' ? "ADMIN CONSOLE" : "USER CONSOLE"}</Button>
                                }

                            </div>
                            <div>
                                <ProfileImageDropdown />
                            </div>
                        </div>

                    </div>
                </nav>
                <Row style={{ position: "absolute", right: 0 }} >
                    <Col>
                        <Toaster />
                    </Col>
                </Row>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        profileData: state.usersReducer.profileData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserProfile: (token) => dispatch(getUserProfile(token))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomNavbar);
