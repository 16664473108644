import React, { useState, useEffect } from "react";
import "./StyleSheets/Sidebar.css";
import { connect } from "react-redux";
import { validateProjectFormList } from "../lib/utils";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button } from "react-bootstrap";
import { fetchconfurigation, getUserProfile } from "../views/users/action";
import { fetchAllFormsList, fetchAllMenuList } from "../views/forms/action";

function Sidebar(props) {
  const [active, setActive] = useState("");
  const [subMenus, setSubMenus] = useState(false);
  const [templateSubMenus, setTemplateSubMenus] = React.useState(false);

  const [selectmenu, setselectmenu] = useState(false);
  const [formListLength, setFormListLength] = useState(0);
  const [persistedState, setpersistedState] = useState(
    localStorage.getItem("key")
  );
  const [projectFormList, setProjectFormList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [configuration, setconfiguration] = useState({});
  const [toolTipPosition, setToolTipPosition] = useState({ x: 0, y: 0 });


  useEffect(() => {
    props.getUserProfile(props.token.access)
    props.allmenulist(props.token.access)
  }, [props.token?.access])

  useEffect(() => {
    props.configuration((data) => {
      setconfiguration(data.results[0] ? data.results[0] : {});
    });
    const updateToolTipPosition = (event) => {
      // Calculate the new position of the tool tip based on mouse position or other factors
      const newX = event.clientX;
      const newY = event.clientY;
      setToolTipPosition({ x: newX, y: newY });
    };
    props.fetchAllFormsList(props.token.access);

    window.addEventListener("mousemove", updateToolTipPosition);
    return () => window.removeEventListener("mousemove", updateToolTipPosition);
  }, []);

 
  
  if (persistedState === "admin") {
    return (
      <div
        className="sidebar-container border-right main-sidebar"
        id="sticky-sidebar"
      >
        <nav id="sidebar" className={props.toggleClass}>
          <ul className="list-unstyled components">
            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Dashboard
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/forms" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/dashboard"
                      ? "#63afab"
                      : null,
                }}
              >
                <Link to="/dashboard">
                  <div
                    className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    <i
                      className="fa fa-home nav_icon large "
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/dashboard"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/dashboard"
                            ? "white"
                            : null,
                      }}
                    >
                      Dashboard
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger>
            {/* <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Custom Dashboard
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/custom-dashboard" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/custom-dashboard" ? "#63afab" : null,
                }}
              >
                <Link to="/custom-dashboard">
                  <div
                    className={`menu-icon ${
                      props.toggleClass ? "d-flex justify-content-end" : ""
                    }`}
                  >
                    <i
                      className="fa fa-gauge nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/custom-dashboard"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/custom-dashboard"
                            ? "white"
                            : null,
                      }}
                    >
                      Custom Dashboard
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger> */}

            {props.profileData?.is_superuser !== true ? (
              <></>
            ) : (
              <>
                {/* <OverlayTrigger
                placement="right"
                overlay={
                  props.toggleClass == "active" ? (
                    <Tooltip
                      id={`tooltip-right`}
                      style={{
                        position: "fixed",
                        left: toolTipPosition.x,
                        top: toolTipPosition.y,
                      }}
                    >
                      Users
                    </Tooltip>
                  ) : (
                    <span></span>
                  )
                }
              >
                <li
                  className={active === "/createUser" ? "active" : null}
                  style={{
                    backgroundColor:
                      window.location.pathname === "/createUser"
                        ? "#63afab"
                        : null,
                  }}
                >
                  <Link to="/createUser">
                    <div
                      className={`menu-icon ${
                        props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                    >
                      <i
                        className="fa fa-user-plus"
                        aria-hidden="true"
                        style={{
                          color:
                            window.location.pathname === "/createUser"
                              ? "white"
                              : null,
                        }}
                      ></i>
                    </div>
                    {props.toggleClass ? null : (
                      <span
                        className="menu-title"
                        style={{
                          color:
                            window.location.pathname === "/createUser"
                              ? "white"
                              : null,
                        }}
                      >
                        Users
                      </span>
                    )}
                  </Link>
                </li>
              </OverlayTrigger> */}


                <OverlayTrigger
                  placement="right"
                  overlay={
                    props.toggleClass === "active" ? (
                      <Tooltip
                        id={`tooltip-right`}
                        style={{
                          position: "fixed",
                          left: toolTipPosition.x,
                          top: toolTipPosition.y,
                        }}
                      >
                        Users
                      </Tooltip>
                    ) : (
                      <span></span>
                    )
                  }
                >
                  <li
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        window.location.pathname === "/createUser" ? "#63afab" : null,
                    }}
                    className={active === "/createUser" ? "active" : null}
                    onClick={() => {
                      setselectmenu(!selectmenu);
                    }}
                  >
                    <a className="d-flex align-items-center justify-content-between">
                      <div>
                        <div
                          className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                            }`}
                        >
                          <i className="fa fa-user-plus" aria-hidden="true"></i>
                        </div>
                        {props.toggleClass ? null : (
                          <>
                            <span className="menu-title">Users</span>

                          </>
                        )}
                      </div>
                      <i
                        className={`fa ${selectmenu ? "fa-chevron-down" : "fa-chevron-right"
                          } ml-2`}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </OverlayTrigger>
                {selectmenu && (
                  <div>
                    <li className={active === "/groups" ? "active" : null}>
                      <a style={{ marginLeft: "15px" }} href="/groups">
                        <div
                          className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                            }`}
                        >
                          <i className="fa-solid fa-user-group" aria-hidden="true"></i>
                        </div>
                        {props.toggleClass ? null : (
                          <span className="menu-title">Create Group</span>
                        )}
                      </a>
                    </li>
                    <li className={active === "/custom-dashboard" ? "active" : null}>
                      <a style={{ marginLeft: "15px" }} href="/custom-dashboard">
                        <div
                          className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                            }`}
                        >
                          <i className="fa fa-gauge" aria-hidden="true"></i>
                        </div>
                        {props.toggleClass ? null : (
                          <span className="menu-title">Custom Dashboard</span>
                        )}
                      </a>
                    </li>
                    <li className={active === "/customlink" ? "active" : null}>
                      <a style={{ marginLeft: "15px" }} href="/customlink">
                        <div
                          className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                            }`}
                        >
                          <i className="fa-solid fa-link" aria-hidden="true"></i>
                        </div>
                        {props.toggleClass ? null : (
                          <span className="menu-title">Custom Links</span>
                        )}
                      </a>
                    </li>
                    <li className={active === "/createUser" ? "active" : null}>
                      <a style={{ marginLeft: "15px" }} href="/createUser">
                        <div
                          className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                            }`}
                        >
                          <i className="fa fa-user-plus" aria-hidden="true"></i>
                        </div>
                        {props.toggleClass ? null : (
                          <span className="menu-title">Create User</span>
                        )}
                      </a>
                    </li>
                  </div>
                )}


              </>
            )}

            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Forms
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/forms" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/forms" ? "#63afab" : null,
                }}
              >
                <Link to="/forms">
                  <div
                    className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    <i
                      className="fa fa-book nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/forms"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/forms"
                            ? "white"
                            : null,
                      }}
                    >
                      Forms
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger>

            {/* <OverlayTrigger
                            placement="right"
                            overlay={
                                props.toggleClass == "active" ?
                                    <Tooltip id={`tooltip-right`} style={{
                                        position: 'fixed',
                                        left: toolTipPosition.x,
                                        top: toolTipPosition.y,
                                    }}>
                                        Custom Forms
                                    </Tooltip> :
                                    <span></span>
                            }
                        >
                            <li className={active === "/customforms" ? "active" : null} >
                                <Link to="/customforms">
                                    <div className={`menu-icon ${props.toggleClass ? 'd-flex justify-content-end' : ''}`}>
                                        <i className="fa fa-pencil-square-o" aria-hidden="true" style={{ fontSize: 17 }}></i>
                                    </div>
                                    {props.toggleClass ? null : <span className="menu-title">Custom Forms</span>}
                                </Link>
                            </li>
                        </OverlayTrigger> */}

            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Projects
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/projects" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/projects" ? "#63afab" : null,
                }}
              >
                <Link to="/projects">
                  <div
                    className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    <i
                      className="fa fa-rocket nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/projects"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/projects"
                            ? "white"
                            : null,
                      }}
                    >
                      Projects
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Workflow
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/workflow" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/workflow" ? "#63afab" : null,
                }}
              >
                <Link to="/workflow">
                  <div
                    className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    <i
                      className="fa fa-sitemap nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/workflow"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/workflow"
                            ? "white"
                            : null,
                      }}
                    >
                      Workflow
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    File Explorer
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/fileexplorer" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/fileexplorer"
                      ? "#63afab"
                      : null,
                }}
              >
                <Link to="/fileexplorer">
                  <div
                    className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    <i
                      className="fa fa-folder-open nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/fileexplorer"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/fileexplorer"
                            ? "white"
                            : null,
                      }}
                    >
                      File Explorer
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Team Management
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/teams" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/teams" ? "#63afab" : null,
                }}
              >
                <Link to="/teams">
                  <div
                    className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    <i
                      className="fa fa-group nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/teams"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/teams"
                            ? "white"
                            : null,
                      }}
                    >
                      Team Management
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger>
            {/* <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Group Management
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/groups" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/groups" ? "#63afab" : null,
                }}
              >
                <Link to="/groups">
                  <div
                    className={`menu-icon ${
                      props.toggleClass ? "d-flex justify-content-end" : ""
                    }`}
                  >
                    <i
                      className="fa-solid fa-user-group nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/groups"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/groups"
                            ? "white"
                            : null,
                      }}
                    >
                      Group Management
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger> */}
            {/* <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Custom Link
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/customlink" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/customlink" ? "#63afab" : null,
                }}
              >
                <Link to="/customlink">
                  <div
                    className={`menu-icon ${
                      props.toggleClass ? "d-flex justify-content-end" : ""
                    }`}
                  >
                    <i
                      className="fa-solid fa-user-group nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/customlink"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/customlink"
                            ? "white"
                            : null,
                      }}
                    >
                      Custom Link
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger> */}
            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Logs
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/logs" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/logs" ? "#63afab" : null,
                }}
              >
                <Link to="/logs">
                  <div
                    className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    <i
                      className="fa fa-exchange nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/logs" ? "white" : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/logs" ? "white" : null,
                      }}
                    >
                      Logs
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Notification
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                className={active === "/notification" ? "active" : null}
                style={{
                  backgroundColor:
                    window.location.pathname === "/notification"
                      ? "#63afab"
                      : null,
                }}
              >
                <Link to="/notification">
                  <div
                    className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                      }`}
                  >
                    <i
                      className="fa fa-bell nav_icon large"
                      aria-hidden="true"
                      style={{
                        color:
                          window.location.pathname === "/notification"
                            ? "white"
                            : null,
                      }}
                    ></i>
                  </div>
                  {props.toggleClass ? null : (
                    <span
                      className="menu-title"
                      style={{
                        color:
                          window.location.pathname === "/notification"
                            ? "white"
                            : null,
                      }}
                    >
                      Notification
                    </span>
                  )}
                </Link>
              </li>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                props.toggleClass == "active" ? (
                  <Tooltip
                    id={`tooltip-right`}
                    style={{
                      position: "fixed",
                      left: toolTipPosition.x,
                      top: toolTipPosition.y,
                    }}
                  >
                    Reports
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <li
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    window.location.pathname === "/reports" ? "#63afab" : null,
                }}
                className={active === "/reports" ? "active" : null}
                onClick={() => {
                  setSubMenus(!subMenus);
                }}
              >
                <a className="d-flex justify-content-between align-items-center">
                  <div>
                    <div
                      className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                        }`}
                    >
                      <i className="fa fa-id-card" aria-hidden="true"></i>
                    </div>
                    {props.toggleClass ? null : (
                      <span className="menu-title">Reports</span>
                    )}
                  </div>
                  <i
                    className={`fa ${subMenus ? "fa-chevron-down" : "fa-chevron-right"
                      } ml-2`}
                    aria-hidden="true"
                  ></i>
                </a>
              </li>
            </OverlayTrigger>
            {subMenus == true && (
              <div>
                <li className={active === "/reports" ? "active" : null}>
                  <a style={{ marginLeft: "15px" }} href="/allReports">
                    <div
                      className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                        }`}
                    >
                      <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                    </div>
                    {props.toggleClass ? null : (
                      <span className="menu-title">All Reports</span>
                    )}
                  </a>
                </li>
                <li className={active === "/userReport" ? "active" : null}>
                  <a style={{ marginLeft: "15px" }} href="/userReport">
                    <div
                      className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                        }`}
                    >
                      <i
                        className="fa fa-address-book-o"
                        aria-hidden="true"
                      ></i>
                    </div>
                    {props.toggleClass ? null : (
                      <span className="menu-title">User Report</span>
                    )}
                  </a>
                </li>
              </div>
            )}
          </ul>
        </nav>
      </div>
    );
  } else {
    // console.log('====================================');
    // console.log("data",props.allmenulists?.template_data);
    // console.log('====================================');
    return (
      <>
        <div
          className="sidebar-container border-right main-sidebar"
          id="sticky-sidebar"
        >
          <nav id="sidebar" className={props.toggleClass}>
            <ul className="list-unstyled components">
              <OverlayTrigger
                placement="right"
                overlay={
                  props.toggleClass == "active" ? (
                    <Tooltip
                      id={`tooltip-right`}
                      style={{
                        position: "fixed",
                        left: toolTipPosition.x,
                        top: toolTipPosition.y,
                      }}
                    >
                      Dashboard
                    </Tooltip>
                  ) : (
                    <span></span>
                  )
                }
              >
                <li className={active === "/forms" ? "active" : null}>
                  <Link
                    to="/dashboard"
                    // onClick={() => {
                    //   props.onClick("/dashboard");
                    // }}
                  >
                    <div
                      className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                        }`}
                    >
                      <i
                        className="fa fa-home nav_icon large "
                        aria-hidden="true"
                      ></i>
                    </div>
                    {props.toggleClass ? null : (
                      <span className="menu-title">Dashboard</span>
                    )}
                  </Link>
                </li>
              </OverlayTrigger>
              {props.allmenulists &&
                props.allmenulists?.form_data?.length > 0 ?


                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      props.toggleClass == "active" ? (
                        <Tooltip
                          id={`tooltip-right`}
                          style={{
                            position: "fixed",
                            left: toolTipPosition.x,
                            top: toolTipPosition.y,
                          }}
                        >
                          Form List
                        </Tooltip>
                      ) : (
                        <span></span>
                      )
                    }
                  >
                    <li
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          window.location.pathname === "/reports"
                            ? "#63afab"
                            : null,
                      }}
                      className={active === "/reports" ? "active" : null}
                      onClick={() => {
                        setSubMenus(!subMenus);
                      }}
                    >
                      <a>
                        <div
                          className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                            }`}
                        >
                          <i
                            className="fa fa-book nav_icon large "
                            aria-hidden="true"
                          ></i>
                        </div>
                        {props.toggleClass ? null : (
                          <span className="menu-title">
                            Form List{" "}
                            <i
                              className={
                                subMenus == true
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                              aria-hidden="true"
                              style={{ marginLeft: "20px" }}
                            ></i>
                          </span>
                        )}
                      </a>
                    </li>
                  </OverlayTrigger>
                  {subMenus == true && (
                    <div>
                      {props.allmenulists &&
                        props.allmenulists?.form_data?.map((item, index) => {
                          console.log(item, 'item')
                          return (
                            <li className={active === "/reports" ? "active" : null}>
                              <a
                                style={{ marginLeft: "15px" }}
                                href={`/forms/${item.id}/view_data`}
                              >
                                <div
                                  className={`menu-icon ${props.toggleClass
                                    ? "d-flex justify-content-end"
                                    : ""
                                    }`}
                                >
                                  <i className="fa fa-book" aria-hidden="true"></i>
                                </div>
                                {props.toggleClass ? null : (
                                  <span className="menu-title">{item.label}</span>
                                )}
                              </a>
                            </li>
                          );
                        })}
                    </div>
                  )}
                </> : null


              }



              {props.allmenulists &&
                props.allmenulists?.template_data?.length > 0 ?
                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      props.toggleClass === "active" ? (
                        <Tooltip
                          id={`tooltip-right`}
                          style={{
                            position: "fixed",
                            left: toolTipPosition?.x || 0,
                            top: toolTipPosition?.y || 0,
                          }}
                        >
                          Templates
                        </Tooltip>
                      ) : (
                        <span></span>
                      )
                    }
                  >
                    <li
                      style={{
                        cursor: "pointer",
                        backgroundColor: window.location.pathname.startsWith("/templates")
                          ? ""
                          : null,
                      }}
                      className={active === "/templates" ? "active" : null}
                      onClick={() => {
                        setTemplateSubMenus(!templateSubMenus);
                      }}
                    >
                      <a>
                        <div
                          className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                            }`}
                        >
                          <i className="fa fa-file-text nav_icon large" aria-hidden="true"></i>
                        </div>
                        {props.toggleClass ? null : (
                          <span className="menu-title">
                            Template List{" "}
                            <i
                              className={
                                templateSubMenus ? "fa fa-angle-up" : "fa fa-angle-down"
                              }
                              aria-hidden="true"
                              style={{ marginLeft: "20px" }}
                            ></i>
                          </span>
                        )}
                      </a>
                    </li>
                  </OverlayTrigger>
                  {templateSubMenus && (
                    <div>
                      {props.allmenulists && props.allmenulists?.template_data?.length > 0 ? (
                        props.allmenulists?.template_data?.map((template, index) => (
                          <li
                            key={index}
                            className={
                              window.location.pathname === `/templates/${template.id}/view`
                                ? ""
                                : null
                            }
                          >
                            <a
                              style={{ marginLeft: "15px" }}
                              href={`/templates/${template.id}/view`}
                            >
                              <div
                                className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                                  }`}
                              >
                                <i className="fa fa-file" aria-hidden="true"></i>
                              </div>
                              {props.toggleClass ? null : (
                                <span className="menu-title">{template.template_name}</span>
                              )}
                            </a>
                          </li>
                        ))
                      ) : (
                        <div style={{ marginLeft: "15px", color: "#777" }}>
                          No templates available.
                        </div>
                      )}
                    </div>
                  )}

                </> : null
              }

              <OverlayTrigger
                placement="right"
                overlay={
                  props.toggleClass == "active" ? (
                    <Tooltip
                      id={`tooltip-right`}
                      style={{
                        position: "fixed",
                        left: toolTipPosition.x,
                        top: toolTipPosition.y,
                      }}
                    >
                      Reports
                    </Tooltip>
                  ) : (
                    <span></span>
                  )
                }
              >
                <li className={active === "/teamReports" ? "active" : null}>
                  <Link to="/allReports">
                    <div
                      className={`menu-icon ${props.toggleClass ? "d-flex justify-content-end" : ""
                        }`}
                    >
                      <i
                        className="fa fa-group nav_icon large"
                        aria-hidden="true"
                      ></i>
                    </div>
                    {props.toggleClass ? null : (
                      <span className="menu-title">Reports</span>
                    )}
                  </Link>
                </li>
              </OverlayTrigger>
            </ul>
          </nav>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.usersReducer.token,
    formList: state.formsReducer.allFormList,
    profileData: state.usersReducer.profileData,
    allmenulists: state.formsReducer.allmenulist
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configuration: (getData) => dispatch(fetchconfurigation(getData)),
    fetchAllFormsList: (accessToken) =>
      dispatch(fetchAllFormsList(accessToken)),
    getUserProfile: (token) => dispatch(getUserProfile(token)),
    allmenulist: (token) => dispatch(fetchAllMenuList(token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
