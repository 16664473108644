import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchData, fetchFields, fetchForms, gettemplateData, gettemplateSearchData } from '../action';
import TemplateActionCommentModal from './TemplateActionCommentModal';
import { Button, Col, Form, Row } from 'react-bootstrap';
import TemplateViewDetails from './TemplateViewDetails';
import { Link, Navigate } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';

class TemplateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWorkflowActionCommentModal: false,
      showTemplateViewDetailsModal: false,
      transitionId: null,
      label: null,
      formid: null,
      template: null,
      data: {},
      selectField: '',
      searchContent: '',
      tabledata: []
    };
  }

  componentDidMount() {
    this.props.gettemplatedata(this.props.token.access, this.props.params.id);
    this.setState({ tabledata: this.props.gettemplatedatas?.results || [] });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gettemplatedatas !== this.props.gettemplatedatas) {
      this.setState({
        data: this.props.gettemplatedatas,
        tabledata: this.props.gettemplatedatas?.results || [],
      });
    }
  }
  onSearchClick = (e) => {
    e.preventDefault();
    const { selectField, searchContent } = this.state;
    const query =
      selectField === "UserName"
        ? `${selectField}='%${searchContent}%'` // Use wildcard search for "UserName"
        : `query=${selectField}=${searchContent}`;


    // const query = `${this.state.selectField}=${this.state.searchContent}`;
    this.props.gettemplateSearchData(
      this.props.token.access,
      this.props.params.id,
      query,
      (response) => {
        this.setState({
          tabledata: response?.results || [], // Update tabledata with search results
        });
      }
    );
  };
  handleClear = (e) => {
    e.preventDefault();
    // Reset search-related state
    this.setState({
      selectField: '', // Clear selected filter field
      searchContent: '', // Clear search input
      tabledata: this.state.data?.results || [], // Reset table data to initial data
    });
  };
  renderTable(data) {

    const { tabledata } = this.state;
    // if (!data || !data.results || data.results.length === 0) {
    //   return <p>No table data available</p>;
    // }
    const { Type, State_Action, results, filter_field } = data;

    if (Type === 'Table with State') {
      const headers = Object.keys(results[0] || {}).filter(
        (key) => !['all_form_id', 'user_id', 'max'].includes(key)
      );

      return (
        <>
          <h4 className='mb-4'>{data?.Name}</h4>
          <Row style={{ display: "flex", alignItems: "center", justifyContent: "end", paddingTop: 8 }}>

            <Col xs="4">
              <Form.Label>Filter</Form.Label>
              <Form.Select aria-label="Default select example" style={{ width: "100%", padding: "0.375rem 0.75rem", border: '1px solid #ced4da', color: '#495057', marginBottom: "15px" }} onChange={event => { this.setState({ selectField: event.target.value }); }}>


                <option value="">Select</option>
                <option value="UserName">User Name</option>
                {filter_field?.map((item) =>
                  <option value={item.id}>{item.label}</option>
                )}

              </Form.Select>
            </Col>
            <Col xs="4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Search</Form.Label>
                <Form.Control type="text" value={this.state.searchContent
                } onChange={event => { this.setState({ searchContent: event.target.value }); }} />

              </Form.Group>

            </Col>
            <Col xs="2">
              <button type="button" className="btn btn-success" style={{ width: "100%", marginTop: 12 }} onClick={(e) => { this.onSearchClick(e) }}>Search</button>
            </Col>
            <Col xs="2">
              <button type="button" className="btn btn-danger" style={{ width: "100%", marginTop: 12 }} onClick={(e) => { this.handleClear(e) }}>Clear</button>
            </Col>
          </Row>
          <table className="table table-bordered">
            <thead>
              <tr>
                {headers.filter((data) => data !== 'template_id').map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tabledata.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.filter((header) => header !== 'template_id').map((header, colIndex) => (
                    <td key={colIndex}>{row[header]}</td>
                  ))}
                  <td>
                    {State_Action?.length > 0 && (
                      <Button
                        title="View"
                        variant="info"
                        size="sm"
                        onClick={() => {
                          const formId = row?.all_form_id;
                          const firstAction = State_Action[0]; // Use the first action
                          const templateId = firstAction?.fields[0]?.template;

                          this.setState(
                            {
                              showTemplateViewDetailsModal: true,
                              formid: formId,
                              template: templateId,
                            },
                            () => {
                              // Callback after state update
                              this.props.getData(this.props.token?.access, formId);
                              console.log(templateId, 'templateId')
                              if (row.template_id) {
                                this.props.getFields(this.props.token?.access, row.template_id);
                                this.props.getForm(this.props.token?.access, row.template_id);
                              }
                            }
                          );
                        }}
                      >
                        View
                      </Button>
                    )}

                    {State_Action.map((action, actionIndex) => (
                      <Button
                        key={actionIndex}
                        className={action.properties}
                        onClick={() => {
                          const formId = row?.all_form_id; // Extract formId from the row
                          const templateId = action?.fields[0]?.template; // Extract templateId from action

                          this.setState(
                            {
                              showWorkflowActionCommentModal: true,
                              transitionId: action.id,
                              label: action.label,
                              formid: formId,
                              template: templateId,
                            },
                            () => {
                              // Callback after state update
                              this.props.getData(this.props.token?.access, formId);
                              // this.props.getFields(this.props.token?.access, formId);
                            }
                          );
                        }}


                        style={{ marginLeft: '5px' }}
                      >
                        {action.label}
                      </Button>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <TemplateActionCommentModal
            showModal={this.state.showWorkflowActionCommentModal}
            handleClose={() => {
              this.setState({ showWorkflowActionCommentModal: false });
              this.props.gettemplatedata(
                this.props.token.access,
                this.props.params.id
              );
            }}
            
            transitionId={this.state.transitionId}
            label={this.state.label}
            id={this.props.params.id}
            selectedData={this.state.data}
            formid={this.state.formid}
            tempid={this.state.template}
            data={this.props.data}
            temp_id={this.props.params.id}
          />
          <TemplateViewDetails
            showModal={this.state.showTemplateViewDetailsModal}
            handleClose={() =>
              this.setState({ showTemplateViewDetailsModal: false })
            }
            transitionId={this.state.transitionId}
            label={this.state.label}
            id={this.props.params.id}
            selectedData={this.state.data}
            formid={this.state.formid}
            tempid={this.state.template}
            data={this.props.data}
          />
        </>
      );
    }

    return <p>No table data available</p>;
  }

  render() {

    const { gettemplatedatas } = this.props;
    console.log(this.props.gettemplatedatas, 'gettemplatedatas');
    return (
      <div className="main-content-container p-4 container-fluid">

        {gettemplatedatas && this.renderTable(gettemplatedatas)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gettemplatedatas: state.formsReducer.templatedata,
  token: state.usersReducer.token,
  data: state.formsReducer.data,
  fields: state.formsReducer.fields,
  form: state.formsReducer.form,
});

const mapDispatchToProps = (dispatch) => ({
  gettemplatedata: (accessToken, tempid) =>
    dispatch(gettemplateData(accessToken, tempid)),
  getData: (accessToken, templateId) => dispatch(fetchData(accessToken, templateId)),
  getFields: (accessToken, template) => dispatch(fetchFields(accessToken, template)),
  getForm: (accessToken, templateId) => dispatch(fetchForms(accessToken, templateId)),
  gettemplateSearchData: (accessToken, id, query, fun) => dispatch(gettemplateSearchData(accessToken, id, query, fun))
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateView);
